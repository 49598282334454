import { Box, Fade, useBoolean } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import {
  assertNever,
  isMobileDisplayTypeBottom,
  isMobileDisplayTypeCustom,
  isMobileDisplayTypeSide,
} from '../../../commons/utils'
import useIsMobile from '../../../hooks/useIsMobile'
import { DesktopDisplayType, MobileDisplayType } from '../../../orval/loov'
import {
  DesktopDisplayHorizontalOffsetType,
  DesktopDisplayVerticalOffsetType,
  PublishedScenarioDetail,
} from '../../../orval/loovPublic'
import EmbeddedMobileCustomFloat from '../../EmbeddedMobileCustomFloat'
import EmbeddedDesktopFloat from './EmbeddedDesktopFloat'
import EmbeddedMobileBottomFloat from './EmbeddedMobileBottomFloat'
import EmbeddedMobileSideFloat from './EmbeddedMobileSideFloat'
import ScenarioPlayerModal from './ScenarioPlayerModal'
import ScenarioPlayerProvider from './ScenarioPlayerProvider'

type EmbeddedFloatingScenarioPlayerPresentationProps = {
  publishedScenario: PublishedScenarioDetail
  desktopDisplayType: DesktopDisplayType
  desktopDisplayVerticalOffset: number
  desktopDisplayVerticalOffsetType: DesktopDisplayVerticalOffsetType
  desktopDisplayHorizontalOffset: number
  desktopDisplayHorizontalOffsetType: DesktopDisplayHorizontalOffsetType
  mobileDisplayType: MobileDisplayType
  scenarioMobileThumbnailId: string | null
  preview?: boolean
}

const EmbeddedFloatingScenarioPlayerPresentation: FC<EmbeddedFloatingScenarioPlayerPresentationProps> = ({
  publishedScenario,
  desktopDisplayType,
  desktopDisplayVerticalOffset,
  desktopDisplayVerticalOffsetType,
  desktopDisplayHorizontalOffset,
  desktopDisplayHorizontalOffsetType,
  mobileDisplayType,
  scenarioMobileThumbnailId,
  preview,
}) => {
  const isMobile = useIsMobile()
  const hasThumbnail = !isMobile || isMobileDisplayTypeBottom(mobileDisplayType)
  const [mode, setMode] = useState<'hidden' | 'default' | 'playing'>('default')
  const [isReady, setIsReady] = useBoolean(!hasThumbnail)
  const containerRef = useRef<HTMLDivElement>(null)

  return mode === 'hidden' || (isMobile && mobileDisplayType === MobileDisplayType.NONE) ? null : (
    <>
      {mode === 'default' && (
        <Box id="loov-float" pos="relative" zIndex={1e9 - 1}>
          <Fade in={isReady} transition={{ enter: { duration: 0.15 } }}>
            {!isMobile ? (
              <EmbeddedDesktopFloat
                displayType={desktopDisplayType}
                verticalOffset={desktopDisplayVerticalOffset}
                verticalOffsetType={desktopDisplayVerticalOffsetType}
                horizontalOffset={desktopDisplayHorizontalOffset}
                horizontalOffsetType={desktopDisplayHorizontalOffsetType}
                description={publishedScenario.modalDescription}
                thumbnailSrc={publishedScenario.scenarioThumbnail?.url}
                showError={preview}
                onReady={setIsReady.on}
                onClick={() => setMode('playing')}
                onClose={() => setMode('hidden')}
              />
            ) : isMobileDisplayTypeSide(mobileDisplayType) ? (
              <EmbeddedMobileSideFloat
                position={mobileDisplayType === MobileDisplayType.SIDE_RIGHT ? 'right' : 'left'}
                onClick={() => setMode('playing')}
                onClose={() => setMode('hidden')}
              />
            ) : isMobileDisplayTypeBottom(mobileDisplayType) ? (
              <EmbeddedMobileBottomFloat
                position={mobileDisplayType === MobileDisplayType.BOTTOM_RIGHT ? 'right' : 'left'}
                description={publishedScenario.modalDescription}
                thumbnailSrc={publishedScenario.scenarioThumbnail?.url}
                showError={preview}
                onReady={setIsReady.on}
                onClick={() => setMode('playing')}
                onClose={() => setMode('hidden')}
              />
            ) : isMobileDisplayTypeCustom(mobileDisplayType) ? (
              scenarioMobileThumbnailId && (
                <EmbeddedMobileCustomFloat
                  position={
                    mobileDisplayType === 'CUSTOM_TOP_LEFT'
                      ? 'top_left'
                      : mobileDisplayType === 'CUSTOM_TOP_RIGHT'
                      ? 'top_right'
                      : mobileDisplayType === 'CUSTOM_SIDE_LEFT'
                      ? 'side_left'
                      : mobileDisplayType === 'CUSTOM_SIDE_RIGHT'
                      ? 'side_right'
                      : mobileDisplayType === 'CUSTOM_BOTTOM_LEFT'
                      ? 'bottom_left'
                      : mobileDisplayType === 'CUSTOM_BOTTOM_RIGHT'
                      ? 'bottom_right'
                      : assertNever(mobileDisplayType)
                  }
                  scenarioMobileThumbnailId={scenarioMobileThumbnailId}
                  onClick={() => setMode('playing')}
                  onClose={() => setMode('hidden')}
                />
              )
            ) : mobileDisplayType === MobileDisplayType.NONE ? null : (
              assertNever(mobileDisplayType)
            )}
          </Fade>
        </Box>
      )}

      {/* 再生画面 */}
      {isReady && (
        <Box id="loov-float-modal" pos="relative" zIndex={1e9} ref={containerRef}>
          <ScenarioPlayerProvider publishedScenario={publishedScenario} preview={preview}>
            <ScenarioPlayerModal
              isOpen={mode === 'playing'}
              onClose={() => setMode('default')}
              containerRef={containerRef}
            />
          </ScenarioPlayerProvider>
        </Box>
      )}
    </>
  )
}

export default EmbeddedFloatingScenarioPlayerPresentation
