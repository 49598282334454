const SwrKey = {
  USER: 'user',
  USERS: 'users',
  STARRED_SCENARIOS: 'starred-scenarios',
  TENANTS: 'tenants',
  SCENARIO_TEMPLATES: 'scenario-templates',
  SCENARIOS: 'scenarios',
  SCENARIO_DETAIL: 'scenario-detail',
  SCENARIO_STATS: 'scenario-stats',
  DAILY_SCENARIO_STATS: 'daily-scenario-stats',
  SCENARIO_STATS_SUMMARY: 'scenario-stats-summary',
  SCENARIO_LOGOS: 'scenario-logos',
  SCENARIO_THUMBNAILS: 'scenario-thumbnails',
  SCENARIO_MOBILE_THUMBNAILS: 'scenario-mobile-thumbnails',
  SLIDES: 'slides',
  // SLIDE_CONTENT はキーに slideId を使うこと
  VIDEO_SLIDE_CONTENTS: 'video-slide-contents',
  // SLIDE_CONTENT はキーに slideId を使うこと
  FORM_SLIDE_CONTENTS: 'form-slide-contents',
  FORM_INPUTS: 'form-inputs',
  FORM_INPUT_OPTIONS: 'form-input-options',
  FORM_SLIDE_THUMBNAILS: 'form-slide-thumbnails',
  // SLIDE_CONTENT はキーに slideId を使うこと
  SELECTION_SLIDE_CONTENTS: 'selection-slide-contents',
  SELECTION_OPTIONS: 'selection-options',
  PUBLISHED_SCENARIOS: 'published-scenarios',
  PUBLISHED_SLIDES: 'published-slides',
  VIDEOS: 'videos',
  IS_ASSOCIATED: 'is-associated',
  VIDEO_ANIMATED_THUMBNAIL_URL: 'video-animated-thumbnail-url',
  LEAD_AND_SESSION: 'lead',
  LEAD_SESSIONS: 'lead-sessions',
  LEAD_SESSION_DETAIL: 'lead-session-detail',
} as const

export type SwrKey = (typeof SwrKey)[keyof typeof SwrKey]

export default SwrKey
